// ENTITY fr.jea.metier.jea.entities.AtelierParticipation generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:27 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ParticipationState } from './ParticipationState';

export class AtelierParticipation {

  idAtelier: number | null = 0;
  idAtelierNiveau: number | null = null;
  idUser: number | null = 0;
  inscriptionDate: Date | null = null;
  numFiche: number | null = 0;
  state: ParticipationState | null = null;
  etaitPretAEmbarquer: boolean | null = false;

  constructor(init?:Partial<AtelierParticipation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.jea.metier.jea.entities.AtelierParticipation'] = AtelierParticipation;

reverseMapping[AtelierParticipation.name] = 'fr.jea.metier.jea.entities.AtelierParticipation';

fields['fr.jea.metier.jea.entities.AtelierParticipation']  = {
    idAtelier: 'java.lang.Integer',
    idAtelierNiveau: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    inscriptionDate: 'java.time.Instant',
    numFiche: 'java.lang.Integer',
    state: 'fr.jea.metier.jea.entities.ParticipationState',
    etaitPretAEmbarquer: 'java.lang.Boolean'
};
