// DB ENUM fr.jea.metier.jea.entities.ParticipationState generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:27 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class ParticipationState {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly INSCRIT = new ParticipationState(1, "Inscrit", "INSCRIT");
  static readonly TRAME_ENVOYE = new ParticipationState(2, "Trame envoyée", "TRAME_ENVOYE");
  static readonly ENVOYE = new ParticipationState(3, "Fichier envoyé", "ENVOYE");
  static readonly CORRECTION = new ParticipationState(4, "Correction nécessaire", "CORRECTION");
  static readonly VALIDE = new ParticipationState(5, "Validé", "VALIDE");
  static readonly ABANDON = new ParticipationState(6, "Abandon", "ABANDON");

  static readonly values = [
    ParticipationState.INSCRIT,
 
    ParticipationState.TRAME_ENVOYE,
 
    ParticipationState.ENVOYE,
 
    ParticipationState.CORRECTION,
 
    ParticipationState.VALIDE,
 
    ParticipationState.ABANDON
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ParticipationState.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ParticipationState.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.jea.metier.jea.entities.ParticipationState'] = ParticipationState;
reverseMapping[ParticipationState.name] = 'fr.jea.metier.jea.entities.ParticipationState';
