// fr.jea.metier.user.services.RegisterServiceI generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:22 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { LoginResponse } from '../entities/LoginResponse';
import { EmailAvailability } from '../entities/EmailAvailability';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  register(firstName: string, lastName: string, email: string, password: string): RpcRequestBuilder<LoginResponse> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.register';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.services.dto.LoginResponse');
  }

  emailAvailable(email: string): RpcRequestBuilder<EmailAvailability> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.emailAvailable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.jea.metier.user.dto.EmailAvailability');
  }

  confirmEmail(token: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.confirmEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
