// ENTITY fr.jea.metier.jea.dto.AtelierDTO generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:27 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { AtelierCreateur } from './AtelierCreateur';
import { Atelier } from './Atelier';
import { AtelierParticipation } from './AtelierParticipation';
import { AtelierNiveau } from './AtelierNiveau';
import { UserLiteDTO } from './UserLiteDTO';
import { AtelierCorrecteur } from './AtelierCorrecteur';

export class AtelierDTO {

  atelier: Atelier | null = null;
  niveaux: AtelierNiveau[] | null = [];
  createurs: AtelierCreateur[] | null = [];
  correcteurs: AtelierCorrecteur[] | null = [];
  participations: AtelierParticipation[] | null = [];
  users: UserLiteDTO[] | null = [];

  constructor(init?:Partial<AtelierDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.jea.metier.jea.dto.AtelierDTO'] = AtelierDTO;

reverseMapping[AtelierDTO.name] = 'fr.jea.metier.jea.dto.AtelierDTO';

fields['fr.jea.metier.jea.dto.AtelierDTO']  = {
    atelier: 'fr.jea.metier.jea.entities.Atelier',
    niveaux: 'java.util.List<fr.jea.metier.jea.entities.AtelierNiveau>',
    createurs: 'java.util.List<fr.jea.metier.jea.entities.AtelierCreateur>',
    correcteurs: 'java.util.List<fr.jea.metier.jea.entities.AtelierCorrecteur>',
    participations: 'java.util.List<fr.jea.metier.jea.entities.AtelierParticipation>',
    users: 'java.util.List<fr.jea.metier.user.dto.UserLiteDTO>'
};
