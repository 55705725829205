// DB ENUM fr.jea.metier.user.dto.EmailAvailability generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:27 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EmailAvailability {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly NOT_AVAILABLE = new EmailAvailability(1, "Not available", "NOT_AVAILABLE");
  static readonly ACCOUNT_CREATION = new EmailAvailability(3, "Account creation", "ACCOUNT_CREATION");

  static readonly values = [
    EmailAvailability.NOT_AVAILABLE,
 
    EmailAvailability.ACCOUNT_CREATION
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EmailAvailability.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EmailAvailability.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.jea.metier.user.dto.EmailAvailability'] = EmailAvailability;
reverseMapping[EmailAvailability.name] = 'fr.jea.metier.user.dto.EmailAvailability';
