// ENTITY fr.jea.metier.user.entities.JEAUser generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:27 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class JEAUser {

  idUser: number | null = 0;
  creationDate: Date | null = null;
  lastConnectionDate: Date | null = null;
  facebookId: string | null = '';
  resetPwdToken: string | null = '';
  confirmAccountToken: string | null = '';
  mailConfirmed: boolean | null = false;

  constructor(init?:Partial<JEAUser>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.jea.metier.user.entities.JEAUser'] = JEAUser;

reverseMapping[JEAUser.name] = 'fr.jea.metier.user.entities.JEAUser';

fields['fr.jea.metier.user.entities.JEAUser']  = {
    idUser: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    lastConnectionDate: 'java.time.Instant',
    facebookId: 'java.lang.String',
    resetPwdToken: 'java.lang.String',
    confirmAccountToken: 'java.lang.String',
    mailConfirmed: 'java.lang.Boolean'
};
