// ENTITY fr.jea.metier.jea.entities.AtelierCreateur generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:27 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class AtelierCreateur {

  idAtelier: number | null = 0;
  idUser: number | null = 0;

  constructor(init?:Partial<AtelierCreateur>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.jea.metier.jea.entities.AtelierCreateur'] = AtelierCreateur;

reverseMapping[AtelierCreateur.name] = 'fr.jea.metier.jea.entities.AtelierCreateur';

fields['fr.jea.metier.jea.entities.AtelierCreateur']  = {
    idAtelier: 'java.lang.Integer',
    idUser: 'java.lang.Integer'
};
