// ENTITY fr.jea.metier.jea.dto.AtelierLiteDTO generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:27 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class AtelierLiteDTO {

  idAtelier: number | null = 0;
  name: string | null = '';
  endDate: Date | null = null;
  matiere: string | null = '';
  type: string | null = '';
  discipline: string | null = '';
  maternelle: boolean | null = false;
  elementaire: boolean | null = false;
  dispoDate: Date | null = null;
  nbMaxSlots: number | null = 0;
  nbSlotsTaken: number | null = 0;
  nbMaxSlotsPretAEmbarquer: number | null = 0;
  nbSlotsTakenPretAEmbarquer: number | null = 0;

  constructor(init?:Partial<AtelierLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.jea.metier.jea.dto.AtelierLiteDTO'] = AtelierLiteDTO;

reverseMapping[AtelierLiteDTO.name] = 'fr.jea.metier.jea.dto.AtelierLiteDTO';

fields['fr.jea.metier.jea.dto.AtelierLiteDTO']  = {
    idAtelier: 'java.lang.Integer',
    name: 'java.lang.String',
    endDate: 'java.time.LocalDate',
    matiere: 'java.lang.String',
    type: 'java.lang.String',
    discipline: 'java.lang.String',
    maternelle: 'java.lang.Boolean',
    elementaire: 'java.lang.Boolean',
    dispoDate: 'java.time.Instant',
    nbMaxSlots: 'java.lang.Integer',
    nbSlotsTaken: 'java.lang.Integer',
    nbMaxSlotsPretAEmbarquer: 'java.lang.Integer',
    nbSlotsTakenPretAEmbarquer: 'java.lang.Integer'
};
