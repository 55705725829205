// ENTITY fr.jea.metier.user.dto.JEABundle generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:27 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { JEAUser } from './JEAUser';

export class JEABundle {

  jeaUser: JEAUser | null = null;

  constructor(init?:Partial<JEABundle>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.jea.metier.user.dto.JEABundle'] = JEABundle;

reverseMapping[JEABundle.name] = 'fr.jea.metier.user.dto.JEABundle';

fields['fr.jea.metier.user.dto.JEABundle']  = {
    jeaUser: 'fr.jea.metier.user.entities.JEAUser'
};
