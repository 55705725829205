// ENTITY fr.jea.metier.jea.entities.Atelier generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:27 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Atelier {

  idAtelier: number | null = 0;
  del: boolean | null = false;
  idUserCreator: number | null = 0;
  creationDate: Date | null = null;
  name: string | null = '';
  description: string | null = '';
  estimatedDurationMin: number | null = 0;
  endDate: Date | null = null;
  matiere: string | null = '';
  type: string | null = '';
  discipline: string | null = '';
  maternelle: boolean | null = false;
  elementaire: boolean | null = false;
  nbSlotPretAEmbarquer: number | null = 0;
  dispoDate: Date | null = null;
  nbMaxParticipations: number | null = 0;
  nbMaxSlots: number | null = 0;

  constructor(init?:Partial<Atelier>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.jea.metier.jea.entities.Atelier'] = Atelier;

reverseMapping[Atelier.name] = 'fr.jea.metier.jea.entities.Atelier';

fields['fr.jea.metier.jea.entities.Atelier']  = {
    idAtelier: 'java.lang.Integer',
    del: 'java.lang.Boolean',
    idUserCreator: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    name: 'java.lang.String',
    description: 'java.lang.String',
    estimatedDurationMin: 'java.lang.Integer',
    endDate: 'java.time.LocalDate',
    matiere: 'java.lang.String',
    type: 'java.lang.String',
    discipline: 'java.lang.String',
    maternelle: 'java.lang.Boolean',
    elementaire: 'java.lang.Boolean',
    nbSlotPretAEmbarquer: 'java.lang.Integer',
    dispoDate: 'java.time.Instant',
    nbMaxParticipations: 'java.lang.Integer',
    nbMaxSlots: 'java.lang.Integer'
};
